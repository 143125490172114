import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import { Link } from "react-scroll";

import data from "../../data/menu";

import { Menu, Popover, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { BoltIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link as LinkRoute, Outlet } from "react-router-dom";

import countries from "../../data/language";

const products = [
  {
    name: "Lottery API",
    description: "Run your own lottery",
    href: "/products/api",
    icon: IconOne,
  },
  // {
  //   name: "Automations",
  //   description: "Create your own targeted content",
  //   href: "##",
  //   icon: IconTwo,
  // },
  // {
  //   name: "Reports",
  //   description: "Keep track of your growth",
  //   href: "##",
  //   icon: IconThree,
  // },
];

const MenuLink = () => {
  const { t } = useTranslation();

  const [mobile, setMobile] = useState(false);
  const [open, setOpen] = useState(false);

  const [locale, setLocale] = useState("GB");

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const changeOpen = () => {
    if (mobile) {
      setOpen(!open);
    }
  };

  // const [active,setActive] = useState(false)

  useEffect(() => {
    //change back to gb after-reloading
    i18n.changeLanguage("gb");

    const resize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
      if (window.innerWidth < 768) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, [i18n]);

  useEffect(() => {
    if (mobile) {
      open
        ? (document.documentElement.style.overflow = "hidden")
        : (document.documentElement.style.overflow = "auto");
    }
  }, [open, mobile]);

  return (
    <Menus className="w-full flex flex-row-reverse md:flex-row justify-around ">
      <div className="w-80-S w-50-XS h-a-S h-100-XS fd-r py-3-S py-5-XS px-10 md:z-4 z-10">
        <span
          className={
            open
              ? "l-0 w-full h-full p-f t-0 backdrop-blur-xl d-n-S ts-3"
              : "l-100 w-full h-full p-f t-0 d-n-S ts-3"
          }
        />
        <div
          className={
            open
              ? "l-0 w-full h-a-S fd-r-S fd-c-XS ai-c p-r-S p-f-XS l-0 t-0 ts-3 ovh-y-v-S ovh-y-a-XS  "
              : "l-100 w-full h-a-S fd-r-S fd-c-XS ai-c p-r-S p-f-XS l-0 t-0 ts-3 ovh-y-v-S ovh-y-a-XS"
          }
          onClick={() => {
            if (mobile && open) {
              setOpen(!open);
            }
          }}
        >
          <div className="w-full fd-r-S fd-c-XS p-0-S p-20-XS pt-0-S pt-40-XS ai-c ">
            <LinkRoute to="/">
              <img
                className="h-10 w-10 mr-4 "
                src="/gyrowin.png"
                alt="gyrowin"
              />
            </LinkRoute>
            {/* <a
              className="w-a f-white-200 fw-500 fs-8-M fs-10-S fs-25-XS link hv ts-3 mr-4 my-0-S my-10-XS md:text-sm text-2xl font-bold md:font-normal"
              href={data[0].link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t(data[0].name)}
            </a> */}

            {/* <div className="mt-10 md:mt-0">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`
                ${open ? "text-white" : "text-white/90"}
                group inline-flex items-center rounded-md  px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                    >
                      <span className="text-2xl font-bold md:font-normal md:text-sm">
                        {t("Product")}
                      </span>
                      <ChevronDownIcon
                        className={`${open ? "text-white" : "text-white"}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute md:left-0 left-1/2 z-10 mt-3 w-screen max-w-sm md:-translate-x-1 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                          <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                            {products.map((item) => (
                              <LinkRoute
                                key={item.name}
                                to={item.href}
                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/50"
                              >
                                <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                  <item.icon aria-hidden="true" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </LinkRoute>
                            ))}
                          </div>
                          <div className="bg-gray-50 p-4">
                            <a
                              href="##"
                              className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/50"
                            >
                              <span className="flex items-center">
                                <span className="text-sm font-medium text-gray-900">
                                  {t("Documentation")}
                                </span>
                              </span>
                              <span className="block text-sm text-gray-500">
                                {t("Start integrating product")}
                              </span>
                            </a>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div> */}

            <a
              className="w-a f-white-200 fw-500 fs-8-M fs-10-S fs-25-XS link hv ts-3 mr-4 my-0-S my-10-XS md:text-sm text-2xl font-bold md:font-normal"
              href={data[1].link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t(data[1].name)}
            </a>

            {/* {data.map((item) => {
              if (item.link === "") {
                return (
                  <Link
                    className="w-a f-white-200 fw-500 fs-7-M fs-10-S fs-25-XS link hv ts-3 mr-4 my-0-S my-10-XS md:text-sm text-2xl font-bold md:font-normal"
                    to={item.scroll}
                    onClick={() => changeOpen()}
                    spy={true}
                    smooth={true}
                    key={t(item.name)}
                  >
                    {t(item.name)}
                  </Link>
                );
              } else {
                return <></>;
              }
            })} */}
            <a
              className="w-a f-white-200 fw-500 fs-8-M fs-10-S fs-25-XS link hv ts-3 mr-4 my-0-S my-10-XS md:text-sm text-2xl font-bold md:font-normal"
              href={data[2].link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t(data[2].name)}
            </a>

            <a
              className="w-a f-white-200 fw-500 fs-8-M fs-10-S fs-25-XS link hv ts-3 mr-4 my-0-S my-10-XS md:text-sm text-2xl font-bold md:font-normal"
              href={data[3].link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t(data[3].name)}
            </a>

            {/* <LinkRoute
              className="w-a f-white-200 fw-500 fs-8-M fs-10-S fs-25-XS link hv ts-3 mr-4 my-0-S my-10-XS md:text-sm text-2xl font-bold md:font-normal"
              to={data[4].link}
              // target="_blank"
              // rel="noreferrer noopener"
            >
              {t(data[4].name)}
            </LinkRoute> */}
          </div>
        </div>

        <svg
          onClick={() => setOpen(!open)}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          className="w-fix-40 p-a r-fix-s-25 t-fix-s-25 d-n-S d-b-s d-b-XS"
        >
          <rect width="20" height="2" rx="1" fill="white" />
          <rect x="4" y="8" width="16" height="2" rx="1" fill="white" />
          <rect y="16" width="20" height="2" rx="1" fill="white" />
        </svg>
      </div>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-select-currency"
            select
            label=""
            value={locale}
            onChange={(event) => {
              setLocale(event.target.value);
              changeLanguage(event.target.value.toLocaleLowerCase());
            }}
            helperText=""
          >
            {countries.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                <img
                  className="mr-1"
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Box>
      {/* <Outlet /> */}
    </Menus>
  );
};

function IconOne() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#87CEEB" />
      <path
        d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
        stroke="#0077b6"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
        stroke="#0096c7"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
        stroke="#0096c7"
        strokeWidth="2"
      />
    </svg>
  );
}

function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  );
}

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
      <rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
      <rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
      <rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
      <rect x="29" y="16" width="2" height="20" fill="#FB923C" />
      <rect x="33" y="12" width="2" height="24" fill="#FB923C" />
    </svg>
  );
}

const Menus = styled.div`
  .MuiTextField-root {
    width: 10ch !important;
    color: white;
  }

  #outlined-select-currency {
    color: white;
  }

  fieldset {
    border: none;
  }

  svg {
    color: white;
  }
`;

export default MenuLink;

// TODO add a interface for the menulik proptype
