import React from "react";
import styled from "styled-components";
// import RoadmapAnim from "../Animations/roadmap";

import { useTranslation } from "react-i18next";

import Loading from "../Animations/r_loading";
import data from "../../data/roadmap";

function Map() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
      />
    </svg>
  );
}

function Confirm() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path
        fillRule="evenodd"
        d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function Check() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function Milestone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
      />
    </svg>
  );
}

function End() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
      />
    </svg>
  );
}

const Roadmap = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        id="Roadmap"
        className="w-100  bg-neutral-900 flex flex-col pb-6 px-6 md:pb-0 md:px-0"
      >
        <div className="w-full md:w-4/5 mx-auto w-100-XS d-f flex-row jc-c">
          <div className="flex flex-col  mr-2 md:mx-8 ai-c ">
            <div className="h-20 md:h-44 w-1 rounded-md bg-gradient-to-b from-neutral-900 to-purple-500"></div>
            <div className="text-white flex ai-c mb-6 mt-6">
              <Map />
              <span className="absolute z-1 h-5 w-6 l-44 b-10 r-0 bg-purple-400 blur-lg"></span>
            </div>
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-purple-500 to-purple-500"></div>
          </div>

          <div className="flex flex-row">
            <div className=" mx-auto flex flex-col pt-16 pb-10 md:pt-28 md:pb-15">
              <div className="w-full md:w-4/5  text-2xl text-white mb-4 font-semibold flex flex-row ai-c pt-1">
                {t("Roadmap")}
              </div>
              <div className="w-full md:w-4/5 text-2xl md:text-5xl leading-0 md:leading-12 tracking-custom font-semibold text-white ws-4 mb-8">
                <span className="text-purple-400 ">
                  {t("Checkout what we have achieved")}
                </span>
                {t(
                  " so far and our milestones to make Gyrowin even better in future."
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row ">
          <div className="flex flex-col mx-2 md:mx-8 ai-c  ">
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-neutral-900 via-amber-500 to-neutral-900"></div>
            {/* <div className="text-white flex ai-c mb-6 mt-6"> */}
            <div className="w-6"></div>
            {/* </div> */}
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-pink-500 flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Confirm />
          </div>
          <div className="text-purple-400 flex flex-col 	">
            <span className="font-extrabold text-3xl md:text-6xl">
              {t("Q4 2022 ")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-20 md:h-20 w-1 rounded-md bg-gradient-to-b from-purple-500 to-purple-500"></div>
          </div>
          <div className="flex flex-col">
            <div>
              <div className="flex flex-row text-white font-normal">
                <span className="text-green-500 mr-2">
                  <Check />
                </span>
                {t("Lottery website")}
              </div>
              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-green-500 mr-2">
                  <Check />
                </span>
                {t("Lottery smart contract")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-pink-500 flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Confirm />
          </div>
          <div className="text-purple-400 flex flex-col 	">
            <span className=" font-extrabold text-3xl md:text-6xl ">
              {t("Q1 2023 ")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-12 md:h-12 w-1 rounded-md bg-gradient-to-b from-purple-500 to-purple-500"></div>
          </div>

          <div className="flex flex-col">
            <div>
              <div className="flex flex-row text-white font-normal">
                <span className="text-green-500 mr-2">
                  <Check />
                </span>
                {t("Launch 6/41 Lottery on Binance Testnet")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-pink-500 flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Confirm />
          </div>
          <div className="text-white flex flex-col 	">
            <span className=" font-extrabold text-3xl md:text-6xl">
              {t("Q2 2023 ")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-20 md:h-20 w-1 rounded-md bg-gradient-to-b from-neutral-900 via-purple-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-col">
            <div>
              <div className="flex flex-row text-white font-normal mb-1">
                <span className="text-green-500 mr-2">
                  <Check />
                </span>
                {t("Gyrowin Money plant vault contract")}
              </div>

              <div className="flex flex-row text-white font-normal">
                <span className="text-green-500 mr-2">
                  <Check />
                </span>
                {t("Gyrowin Governance contract")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-white flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Milestone />
          </div>
          <div className="text-white flex flex-col 	">
            <span className=" font-extrabold text-3xl md:text-6xl ">
              {t("Q3 2023 ")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-start ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-40 md:h-36 w-1 rounded-md bg-gradient-to-b from-neutral-900 via-purple-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-col pt-4">
            <div>
              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Smart contract audit")}
              </div>

              <div className="flex flex-row text-white font-normal">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Tokenomics audit")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Website UI/UX update")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-green-500 mr-2">
                  <Check />
                </span>
                {t("Launch $GW on Binance DEX")}
              </div>

            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-white flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Milestone />
          </div>
          <div className="text-white flex flex-col 	">
            <span className=" font-extrabold text-3xl md:text-6xl ">
              {t("Q4 2023 ")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-start ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-96 md:h-72 w-1 rounded-md bg-gradient-to-b from-neutral-900 via-purple-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-col pt-4">
            <div>
            <div className="flex flex-row text-white font-normal">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Launch 6/41 Lottery in Binance Mainnet")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Launch Money Plant Vault in Binance Mainnet")}
              </div>

              <div className="flex flex-row text-white font-normal">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Gyrowin mobile app")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Lottery support through telegram bot")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Launch Gyrowin governance")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Launch Lottery on Tron, Solana and Polygon Mainnet")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("10 minutes lottery")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("5/9 lottery")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t(" Gyrowin Referral system")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-white flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Milestone />
          </div>
          <div className="text-white flex flex-col 	">
            <span className=" font-extrabold text-3xl md:text-6xl">
              {t("2024")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-start ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-88 md:h-72 w-1 rounded-md bg-gradient-to-b from-neutral-900 via-purple-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-col pt-4">
            <div>
              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t(
                  "Omnichain Borrowing & Lending in Solana, Binance, Avalanche, Polygon and Ethereum"
                )}
              </div>

              <div className="flex flex-row text-white font-normal">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Gyrowin Point System")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Launch $GW on Ethereum, Tron, Polygon and Solana Dex")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Launch $GW on Major CEXs")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Instant scratch NFT lottery")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Dex Trading info display & alarm")}
              </div>

              <div className="flex flex-row text-white font-normal">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Dex frontrun security with wallet")}
              </div>

              <div className="flex flex-row text-white font-normal my-1">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Cross-chain DEX trading")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-white flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <Milestone />
          </div>
          <div className="text-white flex flex-col 	">
            <span className=" font-extrabold text-3xl md:text-6xl">
              {t("2025")}
            </span>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-start ">
          <div className="flex flex-col mr-2 md:mx-8 items-center">
            <div className="w-6 h-1"></div>
            <div className="h-16 md:h-16 w-1 rounded-md bg-gradient-to-b from-neutral-900 via-purple-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-col pt-4">
            <div>
              <div className="flex flex-row text-white font-normal">
                <span className="text-gray-500 mr-2">
                  <Check />
                </span>
                {t("Gyrowin 2nd layer blockchain")}
              </div>
            </div>
          </div>
        </div>

        <div className="w-80-S mx-auto w-100-XS d-f flex-row items-center">
          <div className="text-white flex flex-col mr-2 md:mx-8 ai-c my-2 ">
            <End />
          </div>
        </div>
      </div>
    </>
  );
};

const Content = styled.div`
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);

  ul {
  }
  h2 {
    font-family: "Staatliches", cursive;
    color: #ffd500;
    text-shadow: 0px 0px 2px #ffd500;
  }

  li {
    list-style-type: none;
    text-align: left;
    font-family: "Poppins", sans-serif;
  }
`;

const InfoStyle = styled.div`
  color: white;
  margin: 5% 0px 5% 0px;
`;
export default Roadmap;
