import React from "react";
import styled from "styled-components";

import data from "../data/intro";

import { useTranslation } from "react-i18next";
import Header from "../components/Header/header";

import { useState } from "react";
import { Disclosure, Tab } from "@headlessui/react";
import { GiftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function DownArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4 ml-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
      />
    </svg>
  );
}

const Api = () => {
  const { t } = useTranslation();

  return (
    <Wrapper className="bg-neutral-900 min-h-screen">
      <Header></Header>
      <Main className="w-full px-5-S  items-center mb-0-XS my-20">
        {/* <img
          className="p-a-XS r-0-XS b-40-XS d-n-S w-70-XS"
          src="/images/main/lottery_main.png"
          alt=""
        /> */}
        <Content className="w-full ai-c">
          {data.map((intro) => (
            <div
              className="w-80-S mx-auto w-100-XS d-f fd-c ai-t-XS py-5-S px-10-XS flex justify-center items-center"
              key="intro.title"
            >
              <div className="w-100-XS pl-4-S p-15-XS text-5xl md:text-7xl text-center  justify-center text-stone-100">
                {t("Start a  ")}
                <span className=" one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                  {t(" 100% transparent lottery ")}
                </span>
                {t("in the world")}
              </div>

              <span className="text-xl font-normal text-center text-white mt-5">
                {t(
                  " With an impossible-to-manipulate lottery number generator"
                )}
              </span>
            </div>
          ))}
        </Content>
        <div>
        {/* <button
          onClick={() => {
            console.log("hey");
          }}
          className=" mr-3  box-border lower-case  bg-indigo-600 hover:bg-indigo-500 py-[11px]  px-10 rounded-md text-white shadow-md"
        >
          {t("Get Started")}
        </button> */}
          {/* <Link
            to="/pricing"
            className="  border hover:border-2 hover:py-[11px] hover:px-[25px] box-border lower-case z-20 hover:border-white  border-gray-400 py-[12px] px-[26px] rounded-md my-3 text-white shadow-md"
          >
            {t("Check pricing")}
          </Link> */}
        </div>

        <Content className="w-full ai-c text-center">
          {data.map((intro) => (
            <div
              className="w-80-S mx-auto w-100-XS d-f fd-c ai-t-XS py-5-S px-10-XS flex justify-center items-center"
              key="intro.title"
            >
              <div className="w-100-XS pl-4-S p-15-XS text-5xl md:text-5xl  justify-center text-stone-100">
                {t("Get the complete ")}
                <span className=" one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                  {t(" api package ")}
                </span>
                {t("to start lottery")}
              </div>
            </div>
          ))}
        </Content>

        <Option />

        {/* <LotteryMain /> */}
      </Main>
    </Wrapper>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Option() {
  const { t } = useTranslation();
  let [categories] = useState({
    Monthly: [
      {
        id: 1,
        title: "Business",
        desc: "Advanced collaboration for all lottery business",
        services: [],
        time: 5,
        timeDes:
          "Fetch a verifiable lottery number that is drawn every 5 minutes and integrate it into your application.",
        cost: "15,000",
        duration: "month",
      },
    ],
    Yearly: [
      {
        id: 1,
        title: "Business",
        desc: "Advanced collaboration for all lottery business",
        services: [],
        time: 5,
        timeDes:
          "Fetch a verifiable lottery number that is drawn every 5 minutes and integrate it into your application.",
        cost: "165,000",
        duration: "year",
        extra: "1 month free",
      },
    ],
  });

  const [selected, setSelected] = useState(categories.Monthly[0]);

  return (
    <div className="w-full max-w-md px-2 py-10 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white text-blue-700 shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {category}
              {category === "Yearly" && (
                <span className=" ml-2 text-xs one text-purple-400 bg-gradient-to-r from-purple-500 to-pink-500">
                  {"Get 1 month free"}
                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((posts, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
              )}
            >
              <ul>
                {posts.map((post) => (
                  <li
                    key={post.id}
                    className="relative rounded-md p-3 hover:bg-gray-100"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <h3 className="text-3xl font-bold leading-5 my-3">
                        {post.title}
                      </h3>
                      <p>{post.desc}</p>

                      {/* {post.duration !== "year" && (
                        <>
                          <div className="flex flex-row">
                            <h2 className="text-7xl one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                              {post.cost}
                            </h2>
                            <span className="text-black text-2xl font-normal">
                              $
                            </span>
                          </div>

                          <p className="text-gray-500 text-sm">
                            {"per" + " " + t(post.duration)}
                          </p>
                        </>
                      )} */}

                      <button
                        onClick={() => {
                          console.log("hey");
                        }}
                        className="w-full  border hover:border-2 hover:py-[7px] box-border lower-case z-20 hover:border-gray-800  border-gray-400 py-[8px] px-3 rounded-md my-2 text-gray-600 shadow-md"
                      >
                        {t("Contact sales")}
                      </button>
                    </div>

                    {
                      <ul className="mt-3 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                        <li>
                          <div className="font-bold">
                            {post.time} {" " + t("minutes draw")}
                          </div>
                          <span>{post.timeDes}</span>
                        </li>

                        <li>&middot;</li>
                      </ul>
                    }

                    <a
                      href="#"
                      className={classNames(
                        "absolute inset-0 rounded-md",
                        "ring-blue-400 focus:z-10 focus:outline-none focus:ring-2"
                      )}
                    />
                  </li>
                ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

const Content = styled.div``;

const Wrapper = styled.div`
  // height: 100vh;
  // min-height: 200px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  h1 {
    font-weight: 700;
    font-size: 96px !important;
    line-height: 100px !important;
    font-feature-settings: unset;
  }

  p {
    line-height: 150%;
    font-feature-settings: unset;
    font-weight: 400;
  }
`;

export default Api;
