import React from "react";
import Spline from "@splinetool/react-spline";
import styled from "styled-components";
import { useState, useEffect } from "react";

const LotteryMain = ({}) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);
  if (!mobile) {
    return (
      <Wrapper className="w-50">
        <Spline
          className="spline"
          scene="https://prod.spline.design/P7GsEknrjORhxkME/scene.splinecode"
        />
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  position: relative;

  .spline {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;

    @media (max-width: 2000px) {
      transform: scale(0.8) translateX(0px);
      transform-origin: top;
    }

    @media (max-width: 1200px) and (min-width: 889px) {
      transform: scale(0.8) translateX(100px);
      transform-origin: top;
    }

    @media (max-width: 888px) {
      transform: scale(0.8) translateX(120px);
      transform-origin: top;
    }

    @media (max-width: 600px) {
      transform: scale(0.5) translateX(-100px);
      right: auto;
      left: 50%;
      margin-left: -600px;
    }

    @media (max-width: 375px) {
      transform: scale(0.45) translateX(-50px);
    }
  }
`;

export default LotteryMain;
