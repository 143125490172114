import React from "react";
import styled from "styled-components";
import data from "../../data/intro";

import { useTranslation } from "react-i18next";


function DownArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4 ml-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
      />
    </svg>
  );
}

const Referral = () => {
  const { t } = useTranslation();

  return (
    <Wrapper className=" bg-neutral-900 flex flex-col">
      
      <Main className="w-100 px-5-S flex flex-col items-center mb-0-XS">
       
        <Content className="w-100-S w-100-XS d-f ai-c">
          {data.map((intro) => (
            <div
              className="w-80-S mx-auto w-100-XS d-f fd-c ai-t-XS py-5-S px-10-XS"
              key="intro.title"
            >
              <div className="w-100-XS p-15-XS text-6xl md:text-9xl ">
               <span className="one text-violet-600 bg-gradient-to-r from-violet-500 to-pink-500">{t("Refer and earn rewards")}</span>
               {/* <span className="one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">{t(" one ")}</span> */}
               {/* {t("place")} */}
              </div>

              <div>
                {t("Join our affiliate program and earn rewards from the referral ")}
              </div>
            </div>
          ))}
        </Content>

        {/* <LotteryMain /> */}
      </Main>

     
    </Wrapper>
  );
};

const Content = styled.div``;

const Ecosystem = styled.div`
  .wrapper-team {
  }
  .normal {
    width: 90px;
    height: 90px;
  }
  .small {
    width: 35px;
    height: 35px;
  }

  .divider {
    list-style-type: none;
    height: 50px;
    border-right: 1px solid #ccc;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  min-height: 200px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;

  h1 {
    font-weight: 700;
    font-size: 96px !important;
    line-height: 100px !important;
    font-feature-settings: unset;
  }

  p {
    line-height: 150%;
    font-feature-settings: unset;
    font-weight: 400;
  }
`;

export default Referral;
