import React from "react";
import styled from "styled-components";
import Spline from "@splinetool/react-spline";
// import styled from "styled-components";

const JackpotAnim = () => {
  return (
    <Wrapper>
      <Spline className="spline" scene="https://prod.spline.design/3DoaacD3h8ScEoGu/scene.splinecode" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
    position: relative;

    .spline {
        position:absolute
        margin:0;
        left:0;
        top:0;
    }
`;

export default JackpotAnim;
