import React from "react";
import styled from "styled-components";

import { useState } from "react";

import va from "@vercel/analytics";

function Dice(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M3 0a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3H3zm1 5.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm8 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1.5 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM4 13.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
    </svg>
  );
}

const MyButton = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const hoverHandler = () => {
    setIsHovering(!isHovering);
  };

  if (props.type === "outline") {
    return (
      <ButtonSyleOutline className="text-white">
        <a href={props.link}>
          <button>{props.name}</button>
        </a>
      </ButtonSyleOutline>
    );
  } else if (props.type === "borderless") {
    return (
      <BorderlessStyle className={props.className}>
        <a href={props.link}>
          <button>{props.name}</button>
        </a>
      </BorderlessStyle>
    );
  } else {
    return (
      <ButtonStyle className={`${props.className} text-white px-5`}>
        <a
          onMouseEnter={hoverHandler}
          onMouseLeave={hoverHandler}
          className="mt-5 mx-0 md:m-0 max-w-fit px-5 py-3 border hover:border-1 rounded-md border-pink-800 hover:border-white flex flex-row items-center justify-center mt-2-S m-10-XS "
          href={props.link}
          onClick={()=> {
            va.track(props.name);
          }}
        >
          {" "}
          {/* <button
            onMouseEnter={hoverHandler}
            onMouseLeave={hoverHandler}
            className="border hover:border-1 rounded-md border-pink-800 hover:border-white flex flex-row items-center justify-center"
          > */}
          {props.name}
          <span className="text-pink-600">
            <Dice
              className={`rotate-45 ${
                isHovering ? `rotate-90` : ``
              } ease-in-out duration-300 ml-4`}
            />
          </span>
          {/* </button> */}
        </a>
      </ButtonStyle>
    );
  }
};

const ButtonStyle = styled.div`
  button {
    background: none;
    padding: 12px 30px;
    white-space: nowrap;
    cursor: pointer;
  }
`;

const BorderlessStyle = styled.div`
  button {
    background: none;
    border: none;
    border-radius: 6px;
    color: #f9c74f;
    font-weight: bold;

    font-family: "Poppins", sans-serif;

    white-space: nowrap;
  }
`;

const ButtonSyleOutline = styled.div`
  button {
    width: auto;
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.1);
    padding: 15px 30px;
    border-radius: 10px;
    color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-weight: bold;

    font-family: "Poppins", sans-serif;

    white-space: nowrap;
    text-align: center;
  }
`;

export default MyButton;
