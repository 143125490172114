
const data = [
    // {   
    //     'id': 1,
    //     'name': 'menu.first',
    //     'scroll': 'Docs',
    //     'link': "https://docs.gyro.win"
    // },
    {   
        'id': 1,
        'name': 'menu.fifth',
        // 'scroll': 'Tokenomics',
        // 'link': "https://docs.gyro.win/overview/tokenomics"
    },
    {   
        'id': 2,
        'name': 'menu.second',
        'scroll': 'Tokenomics',
        'link': "https://docs.gyro.win/overview/tokenomics"
    },
    {   
        'id': 3,
        'name': 'menu.third',
        'scroll': 'Roadmap',
        'link': "https://docs.gyro.win/overview/roadmap"
    },
    {   
        'id': 4,
        'name': 'menu.fourth',
        'scroll': 'Whitepaper',
        // 'link': '/whitepaper.pdf'
        'link': "https://docs.gyro.win"
    },
    {   
        'id': 5,
        'name': 'menu.sixth',
        'link': '/pricing'
        // 'scroll': 'Whitepaper',
        // 'link': '/whitepaper.pdf'
        // 'link': "https://docs.gyro.win"
    },

]

export default data;