import { Wallet, providers, Contract } from "ethers";
import gyrowinABI from "../config/abi/gyrowin.json";
import pancakeRouterABI from "../config/abi/router.json";
import gyrowinBuybackABI from "../config/abi/buyback.json";

import bignumber from "bignumber.js";
import { formatEther, formatUnits } from "@ethersproject/units";

// import dotenv from "dotenv"
// dotenv.config();

const provider = new providers.JsonRpcProvider(
  "https://compatible-snowy-yard.bsc.quiknode.pro/46c672d6d9432694ed9ca51feb1180e0706b17f0/"
);
// const account = new Wallet('0x' + process.env.SECRET_PRIV_KEY, provider)

// let signer = account.connect(provider)

const addresses = {
  // vestingAddress: "0x77797FdB59dFD5B1F77514C6Bf81698178191777",
  token: "0x77774A06271d6A305CAccDBc06f847DEF05c7777",
  router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  usdt: "0x55d398326f99059fF775485246999027B3197955",
  buyback: "0x77787E55B75D22F0fF2807924D7026FE55a83777",
};

export const getContract = ({ abi, address, signer }) => {
  const signerOrProvider = signer;
  return new Contract(address, abi, signerOrProvider);
};

/// get the address of the token contract
export const getGyrowinAddress = () => {
  return getAddress(addresses.token);
};

/// get the address of the buyback contract
export const getBuybackAddress = () => {
  return getAddress(addresses.buyback);
};

export const getPancakeRouterAddress = () => {
  return getAddress(addresses.router);
};

/// get the address of the contracts
const getAddress = (address) => {
  return address;
};

const getGyrowinContract = (signer) => {
  return getContract({ abi: gyrowinABI, address: getGyrowinAddress(), signer });
};

const getBuybackContract = (signer) => {
  return getContract({
    abi: gyrowinBuybackABI,
    address: getBuybackAddress(),
    signer,
  });
};

const getPancakeRouterContract = (signer) => {
  return getContract({
    abi: pancakeRouterABI,
    address: getPancakeRouterAddress(),
    signer,
  });
};

const gyrowinContract = getGyrowinContract(provider);
const pancakeRouterContract = getPancakeRouterContract(provider);
const buybackContract = getBuybackContract(provider);

export const useGetTokenInfo = async () => {
  try {
    const [totalSupply, circulatingSupply] = await Promise.all([
      gyrowinContract.callStatic.totalSupply(),
      gyrowinContract.callStatic.circulatingSupply(),
    ]);
    return [formatUnits(totalSupply, 18), formatUnits(circulatingSupply, 18)];
  } catch (error) {
    console.error("Gyrowin contract", error);
    return null;
  }
};

export const useGetTokenBuyback = async() =>{
  try {
    const totalBuyback = await buybackContract.callStatic._buybackTotal();
    return [formatUnits(totalBuyback,18)]
  } catch(error) {
    console.error("Buyback contract", error);
    return null;
  }
}

export const useGetTokenPrice = async () => {
  try {
    const getAmountsOut = await pancakeRouterContract.callStatic.getAmountsOut(
      1,
      [addresses.usdt, addresses.token]
    );

    return getAmountsOut;
  } catch (error) {
    console.error("pancake router contract", error);
    return null;
  }
};
