import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useCountdown } from "./timer";
import DateTimeDisplay from "./dateTimeDisplay";

const ShowDraw = ({ days, hours, minutes, seconds, completed }) => {
  const { t } = useTranslation();
  return (
    <div className="d-f fd-c ai-c w-100">
      <DrawStyle className="p-10-XS">
        <DateTimeDisplay className="d-f fd-r ac-b" value={days} type={"D"} />
        <DateTimeDisplay
          className="fd-r ac-b d-f mx-5-S"
          value={hours}
          type={"H"}
        />
        <DateTimeDisplay className="fd-r ac-b d-f" value={minutes} type={"M"} />
      </DrawStyle>
      <div>
        <h2 className="fs-8-S fs-30-XS fw-800">{t("prizepool.fourth")}</h2>
      </div>
    </div>
  );
};

const Draw = ({ date }) => {
  const [days, hours, minutes, seconds] = useCountdown(date);

  return (
    <ShowDraw days={days} hours={hours} minutes={minutes} seconds={seconds} />
  );
};

const DrawStyle = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Staatliches", cursive;
  font-weight: 700;
  color: #ffd500;
  letter-spacing: 10px;
  justify-content: center;

  h1 {
    text-shadow: 0px 0px 2px #ffd500;
    font-size: 60px;
  }

  h3 {
    color: white;
  }

  .ac-b {
    align-items: baseline;
  }
`;

export default Draw;
