

const baseUrl = "https://api.dune.com/api/v1"; // dune api

export default class ChartApi {
  static URL = `${baseUrl}/`;

  static async get(path) {
    try {
      const response = await fetch(this.URL + path + process.env.REACT_APP_DUNE_API_KEY);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.success
          ? responseJson.data
          : responseJson
          ? responseJson
          : null;
      }
    } catch (err) {
      console.log(`Error fetching from Chart API ${path}: ${err}`);
    }
    return null;
  }

  static async getBuybacks(){
    return ChartApi.get(`query/3058561/results?api_key=`);
  }

  static async totalBuyBacks(){
    return ChartApi.get(`query/3129136/results?api_key=`)
  }

  static async getBurn(){
    return ChartApi.get(`query/3065964/results?api_key=`);
  }

}

export const CHART_DATA_FEED = `${baseUrl}/query/`;
