import { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import Skeleton from "@mui/material/Skeleton";

// import Prize from "../Prize/prize";
// import Statistics from "../Statistics/statistics";

import * as numbers from "../../utils/numbers";

import { useGetTokenInfo, useGetTokenPrice } from "../../hooks/useProvider.tsx";
import BuybackChart from "../BuybackChart";

import BurnChart from "../BurnChart";
import { useGetTokenBuyback } from "../../hooks/useProvider.tsx";

import ChartApi from "../../utils/fetchBuyback";

// import data from "../../data/tokenomics";
function Love() {
  return (
    <svg
      className="w-6 h-6"
      data-view-component="true"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path d="M2.828 4.328C5.26 1.896 9.5 1.881 11.935 4.317c.024.024.046.05.067.076 1.391-1.078 2.993-1.886 4.777-1.89a6.22 6.22 0 0 1 4.424 1.825c.559.56 1.023 1.165 1.34 1.922.318.756.47 1.617.468 2.663 0 2.972-2.047 5.808-4.269 8.074-2.098 2.14-4.507 3.924-5.974 5.009l-.311.23a.752.752 0 0 1-.897 0l-.312-.23c-1.466-1.085-3.875-2.869-5.973-5.009-2.22-2.263-4.264-5.095-4.27-8.063a6.216 6.216 0 0 1 1.823-4.596Zm8.033 1.042c-1.846-1.834-5.124-1.823-6.969.022a4.712 4.712 0 0 0-1.382 3.52c0 2.332 1.65 4.79 3.839 7.022 1.947 1.986 4.184 3.66 5.66 4.752a78.214 78.214 0 0 0 2.159-1.645l-2.14-1.974a.752.752 0 0 1 1.02-1.106l2.295 2.118c.616-.52 1.242-1.08 1.85-1.672l-2.16-1.992a.753.753 0 0 1 1.021-1.106l2.188 2.02a18.963 18.963 0 0 0 1.528-1.877l-.585-.586-1.651-1.652c-1.078-1.074-2.837-1.055-3.935.043-.379.38-.76.758-1.132 1.126-1.14 1.124-2.96 1.077-4.07-.043-.489-.495-.98-.988-1.475-1.482a.752.752 0 0 1-.04-1.019c.234-.276.483-.576.745-.893.928-1.12 2.023-2.442 3.234-3.576Zm9.725 6.77c.579-1.08.92-2.167.92-3.228.002-.899-.128-1.552-.35-2.08-.22-.526-.551-.974-1.017-1.44a4.71 4.71 0 0 0-3.356-1.384c-1.66.004-3.25.951-4.77 2.346-1.18 1.084-2.233 2.353-3.188 3.506l-.351.423c.331.332.663.664.993.998a1.375 1.375 0 0 0 1.943.03c.37-.365.748-.74 1.125-1.118 1.662-1.663 4.373-1.726 6.06-.045.56.558 1.12 1.12 1.658 1.658Z"></path>
    </svg>
  );
}

function Help({ classname }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="pink"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={`w-6 h-6 ${classname}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
      />
    </svg>
  );
}

function Fire({ classname }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="orange"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={`w-6 h-6 ${classname}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
      />
      <path
        strokeLinecap="round"
        fill="yellow"
        strokeLinejoin="round"
        d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
      />
    </svg>
  );
}

function Tokeno() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
      />
    </svg>
  );
}

function External() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
      />
    </svg>
  );
}

const Structure = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const [circulatingSupply, setCirculatingSupply] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [totalBurned, setTotalBurned] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);

  const [totalBuyback, setTotalBuyback] = useState(null);

  useEffect(() => {
    const FetchBuyback = async () => {
      const total = await useGetTokenBuyback();
      if (!total) return null;

      setTotalBuyback(parseInt(total).toLocaleString("en-US"));
    };
    FetchBuyback();
  }, [totalBuyback]);

  useEffect(() => {
    const FetchInfo = async () => {
      const [total, circulating] = await useGetTokenInfo();
      const [, price2] = await useGetTokenPrice();

      setCirculatingSupply(parseInt(circulating));
      setTotalSupply(parseInt(total).toLocaleString("en-US"));
      setTotalBurned(
        (parseInt(5000000000) - parseInt(total)).toLocaleString("en-US")
      );
      setTokenPrice((1 / parseInt(price2)).toFixed(7));
    };
    FetchInfo();
  }, [circulatingSupply]);

  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-80-S mx-auto flex flex-col bg-zinc-900 border border-stone-800 rounded-lg relative`}
    >
      <img
        className="absolute w-28 h-28 md:w-40 md:h-40 right-0 md:-top-20 -top-16"
        src="/images/tokenomics/structure.png"
        alt="tokenomics"
      />

      <span className="absolute h-10 w-10 md:right-16 right-10 -top-10 bg-blue-400 blur-2xl"></span>
      <a href="#tokenDataLive" className="hidden-link">
        hidden
      </a>
      <div
        id="tokenDataLive"
        className="w-full md:w-2/4 p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4"
      >
        <span className="text-white">
          {t("Numbers speak for themselves. ")}
        </span>
        {t(" You can buy $GYROWIN here.")}
        {/* <div className="flex flex-row mt-10 ai-c text-white underlineText max-w-fit">
          {t("Read about GYROWIN Tokenomics")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>

      {/* <div className="flex  flex-col">
        <div className="w-full p-6 pt-0 md:p-20 md:pt-0 md:pb-15 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
          {t("Total Supply")}
          {totalSupply ? (
            <div className="flex flex-row items-end text-blue-400 font-bold text-3xl md:text-5xl md:mt-5 mt-1">
              <span className="flex items-end one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                {totalSupply}
              </span>
              <span className="ml-2 text-white text-2xl">$GW</span>
            </div>
          ) : (
            <Skeleton width={300} height={75} />
          )}
        </div>

        <div className="w-full  p-6 pt-0 md:p-20 md:pt-0 md:pb-15 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
          {t("Circulating Supply")}
          {circulatingSupply ? (
            <div className="flex flex-row items-end text-blue-400 font-bold text-3xl md:text-5xl md:mt-5 mt-1">
              <span className="one text-green-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                {circulatingSupply.toLocaleString("en-US")}
              </span>
              <span className="ml-2 text-white text-2xl">$GW</span>
            </div>
          ) : (
            <Skeleton width={300} height={75} />
          )}
        </div>

        <div className="w-full p-6 pt-0 md:p-20 md:pt-0 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
          {t("Total Marketcap")}
          {tokenPrice ? (
            <div className="flex flex-row items-end text-blue-400 font-bold text-3xl md:text-5xl md:mt-5 mt-1">
              <span className="one text-green-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                {parseInt(
                  tokenPrice * parseFloat(circulatingSupply)
                ).toLocaleString("en-US")}
              </span>
              <span className="ml-2 text-white text-2xl">USD</span>
            </div>
          ) : (
            <Skeleton width={300} height={75} />
          )}
        </div>

        <div className="w-full p-6 pt-0 md:p-20 md:pt-0 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
          {t("Total Buyback")}
          {totalBuyback ? (
            <div className="flex flex-row items-end text-blue-400 font-bold text-3xl md:text-5xl md:mt-5 mt-1">
              <span className="one text-teal-400 bg-gradient-to-r from-teal-500 to-green-500">
                {totalBuyback}
              </span>
              <span className="ml-2 text-white text-2xl">USD</span>
            </div>
          ) : (
            <Skeleton width={300} height={75} />
          )}
        </div>

        <div className="w-full p-6 pt-0 md:p-20 md:pt-0 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
          {t("Total Burned")}
          {totalBurned ? (
            <div className="flex flex-row items-end text-blue-400 font-bold text-3xl md:text-5xl md:mt-5 mt-1">
              <span className="one text-orange-400 bg-gradient-to-r from-yellow-500 to-orange-500">
                {totalBurned}
              </span>
              <span className="ml-2 text-white text-2xl">$GW</span>
            </div>
          ) : (
            <Skeleton width={300} height={75} />
          )}
        </div>
        <div className="grid grid-cols-2">
          <div className="col-span-2 px-0 pr-5 py-4 md:col-span-2">
            <BuybackChart
              xKey={"call_block_time"}
              yKey={"_col1"}
              tickFormat={(x) => `$${numbers.formatYAxis(x)}`}
              token={"USDT"}
              title={"Buyback"}
              startColor={"#18e236"}
              endColor={"#34e04e"}
              lineColor={"#5ddd70"}
            />
          </div>
          <div className="col-span-2 px-0 pr-5 py-4 md:col-span-2">
            <BurnChart
              xKey={"call_block_time"}
              yKey={"_col1"}
              tickFormat={(x) => `${numbers.formatYAxis(x)}`}
              token={"GW"}
              title={"Burn"}
              startColor={"#ffea00"}
              endColor={"#ffb700"}
              lineColor={"#ffc300"}
            />
          </div>
        </div>

        <div>
          <BuybackBurnChart
            xKey={"call_block_time"}
            yKey={"_col2"}
            tickFormat={(x) => `$${numbers.formatYAxis(x)}`}
          />
        </div>
      </div> */}

      {/* <div className="w-full md:w-2/4 px-6 pb-2 pt-0 md:px-20 md:pb-5 md:pt-0 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        {t("Token Distribution")}
      </div> */}

      {/* <div className="w-full px-6 md:px-20 pb-2 md:pb-20 flex flex-col md:flex-row">
        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit text-2xl md:text-4xl font-bold ">
            {t("8.3%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Liquidity")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit text-2xl md:text-4xl font-bold ">
            {t("5%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Marketing")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit text-2xl md:text-4xl font-bold">
            {t("10%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Moneyplant")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit  text-2xl md:text-4xl font-bold ">
            {t("10%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Team")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit  text-2xl md:text-4xl font-bold ">
            {t("17%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("CEX & Loyalty")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit  text-2xl md:text-4xl font-bold ">
            {t("8.3%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Private Sale")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-16 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit  text-2xl md:text-4xl font-bold ">
            {t("0.4%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Fair Launch Presale")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>

        <div className="w-p-12 flex flex-col text-white mr-2 mb-3 md:mb-0">
          <div className="max-w-fit text-2xl md:text-4xl font-bold ">
            {t("41%")}
          </div>
          <span className="mr-5 text-xs font-normal break-keep">
            {t("Reserve")}
          </span>
          <div className="flex flex-row ai-c py-2">
          </div>
        </div>
      </div> */}
    </div>
  );
};

const Mint = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-full md:w-2/4  bg-zinc-900  border border-stone-800 rounded-lg `}
    >
      <div className="w-full p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">
          {t("The Gyrowin Money plant function ")}
        </span>
        {t(
          "enables our plant parents to earn more rewards in $GYROWIN. This function is activated when the burn rate of the total $GYROWIN supply exceeds 5% and meets our required supply-demand ratio. This ensures that our other $GYROWIN holders are not affected."
        )}
        {/* <div className="flex flex-row mt-10 ai-c text-lg md:text-2xl text-white underlineText max-w-fit">
          {t("Read about minting strategy")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Tax = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX,
      y: event.clientY,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className="w-full md:w-49 mt-20 md:mt-0 bg-zinc-900  border border-stone-800 rounded-lg"
    >
      <img
        className="absolute w-40 h-40 md:right-0 md:-top-20 -top-24 -right-1"
        src="/images/tokenomics/tax.png"
        alt="tokenomics"
      />

      <span className="absolute h-10 w-10 right-16 -top-10 bg-blue-400 blur-2xl"></span>

      <div className="w-full p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">{t("$GYROWIN token has a tax. ")}</span>

        {t(
          `That's right, the owner has to pay a 1% tax on buy/sell of the $GYROWIN token. However, this tax is fully rewarded to our Plant Dad and Mom.`
        )}
        {/* <div className="mt-10 text-white underlineText text-lg md:text-2xl max-w-fit flex flex-row ai-c">
          {t("Read about token tax")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Donate = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-80-S mx-auto flex flex-col md:flex-row bg-zinc-900  border border-stone-800 rounded-lg relative`}
    >
      <div className="mx-auto md:absolute w-3/4 h-3/4 md:h-101 md:w-101 md:right-10 md:-top-32">
        <span className="md:absolute h-60 w-60 right-28 top-32 bg-pink-300 blur-3xl opacity-20"></span>
        <img src="/images/tokenomics/donation.png" alt="donation" />
      </div>

      <div className=" w-full md:w-2/4 p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">
          {t("NGO’s working with children’s ")}
        </span>
        {t(
          "  can directly apply for our donation program if they are seeking funds."
        )}

        {/* <div className="flex flex-row mt-10 ai-c text-white underlineText max-w-fit">
          {t("Apply for donation program")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Tokenomics = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-100  bg-neutral-900 flex flex-col px-4 md:px-0">
        <div
          id="Tokenomics"
          className="w-full md:w-4/5 mx-auto d-f flex-row jc-c relative"
        >
          <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c ">
            <div className="h-24 md:h-44 w-1"></div>
            <div className="text-white flex ai-c mb-6 mt-6">
              <Tokeno />
              <span className="absolute z-1 h-5 w-6 l-44 b-10 r-0 bg-blue-300 blur-lg"></span>
            </div>
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-blue-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-row py-20 md:py-32">
            <div className=" mx-auto flex flex-col ">
              <div className="w-80-S  text-2xl text-white mb-4 font-semibold flex flex-row ai-c ">
                {t("Tokenomics")}
              </div>
              <div className="w-full md:w-4/5 text-2xl md:text-5xl leading-0 md:leading-12 tracking-custom font-semibold text-white ws-4 mb-0 md:mb-8 pr-5 md:pr-0">
                <span className="text-blue-300 ">
                  {t("Gyrowin token structure ")}
                </span>
                {t(
                  " is aligned to benefit all the parties invovled in the growth of our ecosystem."
                )}
              </div>
            </div>
          </div>
        </div>

        <Structure />
        <div className="w-80-S mx-auto w-100-XS d-f flex-row ">
          <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c  ">
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-neutral-900 via-blue-500 to-neutral-900"></div>
            {/* <div className="text-white flex ai-c mb-6 mt-6"> */}
            <div className="w-6"></div>
            {/* </div> */}
          </div>
          <div className="flex flex-row py-20 md:py-36">
            <div className=" flex flex-col">
              <div className="w-full text-white">
                {" "}
                <div className="text-blue-400 font-normal">
                  {t("Did you know?")}
                </div>
                <div className="text-blue-300 text-4xl md:text-6xl">
                  {" "}
                  <span>{t("5% ")}</span>
                  {t("of the Lottery ")}
                </div>
                <div className="text-xl md:text-2xl mt-3 ">
                  {t("pool prize is ")}
                  <Fire classname={`inline-block text-amber-400`} />{" "}
                  {t("burned ")}
                  {t("every week in the form of $GYROWIN.")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-4/5 mx-auto flex flex-col md:flex-row md:px-30 jc-sb">
          <Mint />
          <Tax />
        </div>

        {/* <div className="w-full md:w-4/5 mx-auto d-f flex-row jc-c relative justify-start">
          <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c ">
            <div className="h-20 md:h-44 w-1 rounded-md bg-gradient-to-b from-neutral-900 to-pink-500"></div>
            <div className="text-white flex ai-c mb-6 mt-6">
              <Love />
              <span className="absolute z-1 h-5 w-6 l-44 b-10 r-0 bg-pink-300 blur-lg"></span>
            </div>
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-pink-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-row">
            <div className=" mx-auto flex flex-col pt-20 pb-20 md:pt-36 md:pb-36 ">
              <div className="w-80-S  text-2xl text-white mb-4 font-semibold flex flex-row ai-c ">
                {t("Donation")}
              </div>
              <div className="w-full md:w-4/5 text-2xl md:text-5xl leading-0 md:leading-12 tracking-custom font-semibold text-white ws-4 mb-0 md:mb-8 pr-5 md:pr-0">
                <span className="text-pink-300 ">
                  {t("All of our donations")}
                </span>
                {t(" All of our ")} {t("donations")}{" "}
                {t(" will be used for improving children lives.")}
              </div>

              <div className="flex flex-row pt-26">
                <div className=" flex flex-col">
                  <div className="w-full text-white">
                    {" "}
                    <div className="text-pink-400 font-normal">
                      {t("How do we contribute?")}
                    </div>
                    <div className="text-pink-300 text-4xl md:text-6xl">
                      <span>{t("10% ")}</span>
                      {t(" of the Lottery")}
                    </div>
                    <div className="text-xl md:text-2xl mt-3 ">
                      {t(" prize pool is ")}
                      <Help classname={`inline-block text-pink-500`} />{" "}
                      {t("donated every week.")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <Donate /> */}
      </div>
    </>
  );
};

export default Tokenomics;
