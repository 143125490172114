import { useState } from "react";

import { useTranslation } from "react-i18next";

import chainlink from "../../assets/Icons/chainlink-blue.svg";
import thegraph from "../../assets/Icons/thegraph.svg";

function Trophy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-8 "
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
      />
    </svg>
  );
}

function External() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
      />
    </svg>
  );
}

const Tickets = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-full md:w-4/5 mx-auto flex flex-col p-6 bg-th-bkg-2 border border-gray-800 rounded-lg`}
    >
      <img src="/images/history/ticket1.png" alt="ticket1" />
      {/* <img src="/images/history/ticket2.png" alt="ticket2"/> */}
    </div>
  );
};

const Chainlink = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-80-S mx-auto flex flex-col md:flex-row bg-zinc-900  border border-stone-800 rounded-lg`}
    >
      <div className="w-full md:w-2/4 p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4">
        <span className="text-white">
          {t(
            "Chainlink and The Graph are the main building blocks of our protocol's foundation. "
          )}
        </span>
        {t(
          "We use Chainlink for price feeds, automation, and verifiable random number generation, and The Graph for fetching on-chain data."
        )}
        {/* <div className="flex flex-row mt-10 ai-c text-lg md:text-2xl text-white underlineText max-w-fit">
          {t("Read about implementation")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>

      <div className="md:w-2/4 w-full px-10 flex flex-col items-center">
        <div className="h-full w-full md:pt-15 md:pb-15 pt-10 pb-8 flex flex-col ai-c">
          <div className="relative group">
            <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-blue-600 to-blue-600"></div>
            <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-blue-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
              <div className="mr-2">
                {" "}
                <img className="h-7 w-7" src={chainlink} alt="chainlink"></img>
              </div>
              <div className="flex flex-col leading-none text-white">
                <div className="">{t("Price Feed")}</div>
                <div className="font-normal text-xs">{t("From Chainlink")}</div>
              </div>
            </div>
          </div>

          <div className="h-10 w-1 bg-gradient-to-b from-neutral-900 via-blue-500 to-neutral-900"></div>

          <div className="relative group">
            <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-blue-600 to-blue-600"></div>
            <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-blue-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
              <div className="mr-2">
                {" "}
                <img className="h-7 w-7" src={chainlink} alt="chainlink"></img>
              </div>
              <div className="flex flex-col leading-none text-white">
                <div className="">{t("Automation")}</div>
                <div className="font-normal text-xs">{t("From Chainlink")}</div>
              </div>
            </div>
          </div>

          <div className="h-10 w-1  bg-gradient-to-b from-neutral-900 via-blue-500 to-neutral-900"></div>

          <div className="relative group">
            <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-blue-600 to-blue-600"></div>
            <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-blue-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
              <div className="mr-2">
                {" "}
                <img className="h-7 w-7" src={chainlink} alt="chainlink"></img>
              </div>
              <div className="flex flex-col leading-none text-white">
                <div className="">{t("VRF")}</div>
                <div className="font-normal text-xs">{t("From Chainlink")}</div>
              </div>
            </div>
          </div>

          <div className="h-10 w-1   bg-gradient-to-b from-neutral-900 via-blue-500 to-neutral-900"></div>

          <div className="relative group">
            <div className=" absolute blur opacity-40 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-purple-600 to-indigo-500"></div>
            <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-purple-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
              <div className="mr-2">
                {" "}
                <img className="h-7 w-7" src="/gyrowin.png" alt="gyrowin"></img>
              </div>
              <div className="flex flex-col leading-none text-white">
                <div className="">{t("GYROWIN")}</div>
                {/* <div className="font-normal text-xs">{t("From Chainlink")}</div> */}
              </div>
            </div>
          </div>

          <div className="h-10 w-1   bg-gradient-to-b from-neutral-900 via-blue-500 to-neutral-900"></div>

          <div className="relative group">
            <div className=" absolute blur opacity-0 group-hover:opacity-100 -inset-0.5 bg-gradient-to-r from-blue-600 to-blue-600"></div>
            <div className=" flex flex-row  bg-zinc-900  max-w-fit shadow-sm hover:shadow-md hover:shadow-blue-400 px-6 py-3 items-center  border border-gray-600 rounded-lg">
              <div className="mr-2 text-purple-500 text-4xl">
                <img
                  className="h-6 w-6 mr-1"
                  src={thegraph}
                  alt="thegraph"
                ></img>
              </div>
              <div className="flex flex-col leading-none text-white">
                <div className="">{t("SUBGRAPH")}</div>
                <div className="font-normal text-xs">{t("From The Graph")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const History = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full  bg-neutral-900 flex flex-col px-4 md:px-0 ">
        <div className="w-full md:w-4/5 mx-auto  d-f flex-row justify-start">
          <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c ">
            <div className="h-20 md:h-44 w-1 rounded-md bg-gradient-to-b from-neutral-900 to-amber-500"></div>
            <div className="text-white flex ai-c mb-6 mt-6">
              <Trophy />
              <span className="absolute z-1 h-5 w-6 l-44 b-10 r-0 bg-amber-400 blur-lg"></span>
            </div>
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-amber-500 to-neutral-900"></div>
          </div>
          <div className="flex flex-row">
            <div className=" mx-auto flex flex-col py-14 md:py-32 justify-start">
              <div className="w-80-S  text-2xl text-white mb-4 font-semibold flex flex-row ai-c pt-1">
                {t("Lottery")}
              </div>
              <div className="w-full md:w-4/5 text-2xl md:text-5xl leading-0 md:leading-12 tracking-custom font-semibold text-white ws-4 mb-0 md:mb-8 pr-5 md:pr-0 break-keep">
                <span className="text-amber-400 ">
                  {t("Chance to win prizes ")}
                </span>
                {t("by playing Gyrowin lottery. ")}
                {/* {t(
                  "by playing Gyrowin lottery. Our lottery smart contract is powered by Chainlink Automation, which runs draws automatically using Verifiable Randomness to select winning numbers. You can trust that our system is fully decentralized and fair."
                )} */}
              </div>
              {/* <div className="w-50-S  text-3xl leading-8 tracking-custom font-semibold text-zinc-600 ws-4 mb-24">
              <span className="text-white ">{t("Delegate votes  ")}</span>
              {t(
                "for new lottery game every week from various categories, alongside with other governance proposols to improve Gyrowin protocol."
              )}
              
            </div> */}
            </div>
          </div>
        </div>
        <Tickets />
        <div className="w-full md:w-4/5 mx-auto flex flex-row ">
          <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c  ">
            <div className="h-full w-1 rounded-md bg-gradient-to-b from-neutral-900 via-amber-500 to-neutral-900"></div>
            {/* <div className="text-white flex ai-c mb-6 mt-6"> */}
            <div className="w-6"></div>
            {/* </div> */}
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row pt-44 pb-20 md:py-20">
              <div className="w-full md:w-2/4 text-xl md:text-3xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4 break-keep">
                <span className="text-white ">
                  {t("Gyrowin offers ")}
                </span>
                {t(
                  "multiple lotteries. Rewards for brackets with no winners will roll over to the next lottery round."
                )}

                <a href="https://app.gyro.win" target="_blank" rel="noreferrer">
                  <div className="mt-4 md:mt-10 text-lg md:text-3xl text-white underlineText max-w-fit flex flex-row ai-c">
                    {t("Learn about Gyrowin Lottery")}
                    <span className="ml-2">
                      <External />
                    </span>
                  </div>
                </a>
              </div>

              <div className="w-50-S h-full relative">
                <div className="absolute h-40 w-48 md:h-101 md:w-101 -top-52 md:-top-32 -right-5 md:-right-5 bg-th-bkg-2 border border-gray-800 rounded-md px-4 pt-4 border-box">
                  <img src="/images/history/lottery.png" alt="lottery" />
                </div>
              </div>
            </div>

            <div className="flex flex-row pb-24 md:py-36">
              <div className=" flex flex-col">
                <div className=" text-white">
                  {" "}
                  <div className="text-amber-400 font-normal">
                    {t("Wait! there's more?")}
                  </div>
                  <div className="text-amber-400 text-4xl md:text-6xl">
                    {" "}
                    <span className="text-4xl md:text-6xl">{t("60% ")}</span>
                    {t("of the prize pool")}
                  </div>
                  <div className="w-full md:w-4/5 text-xl md:text-2xl mt-3">
                    {t(" amount is shared between the lottery players")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Chainlink />
      </div>
    </>
  );
};

// We provide lots of lottery games such as Mega millions and 6/41 lottery. So don't lose your chance

export default History;
