const countries = [
    // {
    //     code: 'AE',
    //     label: 'United Arab Emirates',
    //     phone: '971',
    // },
    { code: 'CN', label: 'Cn', phone: '86' },
    // {
    //     code: 'DE',
    //     label: 'Germany',
    //     phone: '49',
    //     suggested: true,
    // },
    // { code: 'DK', label: 'Denmark', phone: '45' },
    // { code: 'ES', label: 'Spain', phone: '34' },
    // { code: 'FI', label: 'Finland', phone: '358' },
    // {
    //     code: 'FR',
    //     label: 'France',
    //     phone: '33',
    //     suggested: true,
    // },
    { code: 'GB', label: 'En', phone: '44' },
    // { code: 'GR', label: 'Greece', phone: '30' },

    // { code: 'ID', label: 'Indonesia', phone: '62' },

    // { code: 'IT', label: 'Italy', phone: '39' },
    // {
    //     code: 'JP',
    //     label: 'Japan',
    //     phone: '81',
    //     suggested: true,
    // },
    { code: 'KR', label: 'Ko', phone: '82' },
    // { code: 'KW', label: 'Kuwait', phone: '965' },
    // { code: 'NL', label: 'Netherlands', phone: '31' },
    // { code: 'NO', label: 'Norway', phone: '47' },
    // { code: 'QA', label: 'Qatar', phone: '974' },
    // { code: 'RO', label: 'Romania', phone: '40' },
    // { code: 'RU', label: 'Russian Federation', phone: '7' },
    // { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    // { code: 'SE', label: 'Sweden', phone: '46' },
    // { code: 'SG', label: 'Singapore', phone: '65' },
    // { code: 'TH', label: 'Thailand', phone: '66' },
    // { code: 'TR', label: 'Turkey', phone: '90' },
    // { code: 'VN', label: 'Vietnam', phone: '84' },

];

export default countries;