import React from "react";
import styled from "styled-components";

import MenuLink from "../Menu/menu";

const Header = () => {
  return (
    <Menu className="w-full flex flex-row">
      <MenuLink />
    </Menu>
  );
};

const Menu = styled.div`
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: row;
  }

  li {
    list-style-type: none;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
`;

export default Header;
