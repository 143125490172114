import styled from "styled-components";
import JackpotAnim from "../Animations/jackpotAnim";
import MyButton from "../Buttons/button";
import Draw from "../Draws/draws";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

function Governance({ props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-8 h-8"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
      />
    </svg>
  );
}

function External() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
      />
    </svg>
  );
}

const GovernanceProto = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({});
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className={`w-full bg-zinc-900  border border-stone-800 rounded-lg `}
    >
      
      <div className="w-full p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8  tracking-custom font-semibold text-zinc-600 ws-4  break-keep">
        <span className="text-white  break-keep">{t("Governance portal ")}</span>
        {t(
          "let's you create on-chain proposal approved by the community, and delegate tokens to vote on proposals."
        )}
        {/* <div className="flex flex-row mt-10 ai-c text-lg md:text-2xl text-white underlineText max-w-fit  break-keep">
          {t("Vote on Governance Portal")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const GovernanceForum = () => {
  const { t } = useTranslation();
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    setMousePos({
      x: event.clientX,
      y: event.clientY,
    });
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      className="w-full md:w-49 mb-5 md:mb-0 bg-zinc-900  border border-stone-800 rounded-lg"
    >
      <div className="w-full p-6 md:p-20 text-xl md:text-2xl leading-0 md:leading-8  tracking-custom font-semibold text-zinc-600 ws-4  break-keep">
        <span className="text-white  break-keep">{t("Gyrowin governance forum")}</span>

        {t(
          " is a place where you can discuss about the new proposals with gyrowin community before it can be sumitted for the on-chain voting."
        )}
        {/* <div className="mt-10 text-white underlineText text-lg md:text-2xl max-w-fit flex flex-row ai-c">
          {t("Join Community Forum")}
          <span className="ml-2">
            <External />
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Jackpot = () => {
  const { t } = useTranslation();

  const DAYS_IN_MS = new Date(2023, 2, 25).getTime(); // for jan 15 2023 -> 30 days in added by default

  const dateTimeAfterLaunch = DAYS_IN_MS;

  return (
    <div className="w-100  bg-neutral-900 flex flex-col px-4 md:px-0">
      <div className="w-full md:w-4/5 mx-auto  d-f flex-row jc-c">
        
        <div className="flex flex-col mr-2 md:ml-8 md:mr-8 ai-c ">
        <div className="h-24 md:h-36 w-1 pb-2 rounded-md bg-gradient-to-b from-neutral-900 to-teal-500"></div>
          <div className="text-white flex ai-c mb-6 mt-6">
            <Governance />
            <span className="absolute z-1 h-5 w-6 l-44 b-10 r-0 bg-teal-300 blur-lg"></span>
          </div>
          <div className="h-full w-1 rounded-md bg-gradient-to-b from-teal-500 to-neutral-900"></div>
        </div>
        <div className="flex flex-row">
          <div className=" mx-auto py-24 md:py-32 flex flex-col">
            <div className="w-80-S  text-2xl text-white mb-4 font-semibold flex flex-row ai-c">
              {t("Governance")}
            </div>
            <div className="w-full md:w-4/5 text-2xl md:text-5xl leading-0 md:leading-12 tracking-custom font-semibold text-white ws-4 mb-8 pr-2 md:pr-0  break-keep">
              <span className="text-teal-300  break-keep">
                {t("Take the power in your hand")}
              </span>
              {t(" and join our global community by holding Gyrowin tokens.")}
            </div>
            <div className="w-full md:w-2/4 text-xl md:text-3xl leading-0 md:leading-8 tracking-custom font-semibold text-zinc-600 ws-4  break-keep">
              <span className="text-white  break-keep ">{t("Delegate votes ")}</span>
              {t(
                "for new new governance proposols to improve Gyrowin protocol."
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-4/5 mx-auto flex flex-col md:flex-row px-0 md:px-30 jc-sb">
        {/* <GovernanceForum /> */}
        <GovernanceProto />
      </div>
    </div>
  );
};


export default Jackpot;
