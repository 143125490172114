import React from "react";
import styled from "styled-components";

import LotteryMain from "../Animations/lotterymain";
import MyButton from "../Buttons/button";

import data from "../../data/intro";

import { useTranslation } from "react-i18next";
import Header from "../Header/header";

import binance from "../../assets/Icons/binance.svg";
import polygon from "../../assets/Icons/polygon.svg";
import solana from "../../assets/Icons/solana.svg";
import tron from "../../assets/Icons/tron.svg";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

function DownArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4 ml-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
      />
    </svg>
  );
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <Wrapper className=" bg-neutral-900 flex flex-col">
      <Header></Header>
      <Main className="w-100 h-screen px-5-S flex flex-col items-center mb-0-XS">
        {/* <img
          className="p-a-XS r-0-XS b-40-XS d-n-S w-70-XS"
          src="/images/main/lottery_main.png"
          alt=""
        /> */}
        <Content className="w-100-S w-100-XS d-f ai-c">
          {data.map((intro) => (
            <div
              className="w-80-S mx-auto w-100-XS d-f fd-c ai-t-XS py-5-S px-10-XS text-center"
              key="intro.title"
            >
              {/* <div className="w-100-XS pl-4-S p-15-XS text-6xl md:text-7xl font-semi-bold text-stone-100">
                {t("Let's build  ")}
                <span className="one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                  {t(" transparent ")}
                </span>
                {t(" platform")}
              </div> */}

              <div className="w-100-XS pl-4-S p-15-XS text-6xl md:text-7xl font-semi-bold text-stone-100">
                {t("Play and invest in")}
                <span className="one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">
                  {t(" one ")}
                </span>
                {t(" place")}
              </div>
              {/* <div className="pl-4-S p-15-XS text-white text-xl font-normal md:mt-10 mt-0 mb-5">
                {t(
                  "Providing and managing verifiable on-chain data for creating trustable and secure platfrom"
                )}
              </div> */}

{/* <div className="pl-4-S p-15-XS text-white text-xl font-normal md:mt-10 mt-0 mb-5">
                {t(
                  "We bridge web2 businesses to web3"
                )}
              </div> */}

              {/* <Link
                to="/products/api"
                className="w-[255px] mx-auto text-center border hover:border-2 hover:py-[11px] hover:px-[25px] box-border lower-case z-0 hover:border-white  border-gray-400 py-[12px] px-[26px] rounded-md my-3 text-white shadow-md"
              >
                {t("Start with us")} <ChevronRightIcon className="h-5 w-5 inline-block"/>
              </Link> */}

              <a
                href="https://app.gyro.win"
                target="_blank"
                rel="noreferrer"
                className="w-[255px] mx-auto text-center border hover:border-2 hover:py-[11px] hover:px-[25px] box-border lower-case z-0 hover:border-white  border-gray-400 py-[12px] px-[26px] rounded-md my-3 text-white shadow-md"
              >
                {t("Launch App")} <ChevronRightIcon className="h-5 w-5 inline-block"/>
              </a>

              {/* <div className="w-100-XS pl-4-S p-15-XS text-8xl md:text-9xl  text-stone-100">
               {t("Play and invest in")}
               <span className="one text-purple-400 bg-gradient-to-r from-purple-500 to-indigo-500">{t(" one ")}</span>
               {t("place")}
              </div>

              <MyButton link="https://app.gyro.win" className="d-n-S" name={t("intro.button")} />

              <p className="hidden md:visible w-95-S w-100-XS p-4-S p-15-XS fs-14-M fs-8-S fs-20-XS text-stone-400">
                {t("Gyrowin is a multi-chain ")}
                <span className="text-white font-semibold">
                  {t("decentralized gaming and finance protocol.")}
                </span>
                {t(" Deposit to earn rewards while playing lottery.")}
              </p>
              <MyButton
                link="https://app.gyro.win"
                className="d-n-XS md:py-20"
                name={t("intro.button")}
              /> */}
            </div>
          ))}
        </Content>

        {/* <LotteryMain /> */}
      </Main>

      {/* <Ecosystem
        id="Ecosystem"
        className="d-n-XS md:visible w-80-S mx-auto w-100-XS d-f fd-c jc-c ai-l f-white-200 px-10-XS py-15-XS bg-neutral-900"
      >
        <div className="mt-5-S px-7-S fw-500 flex flex-col md:flex-row items-center justify-start text-zinc-500">
          {t("ecosystem.title")} <DownArrow />
        </div>

        <div className="w-50 wrapper-team my-15-XS p-5-S py-10-XS px-20-XS rad-fix-10-S rad-fix-30-XS">
          <ul className="w-100 mt-10-XS d-f fd-r-S fd-c-XS jc-sa ai-c">
            <li className="d-f fd-r mb-5-XS ai-c jc-c-XS">
              <div className="text-3xl font-bold">4</div>
              <div className="d-f fd-c fs-5 fw-600 px-2">
                <div>{t("Blockchain")}</div>
                <div>{t("networks")}</div>
              </div>
            </li>
            <li className="divider py-2"></li>
            <li className="d-f fd-r mb-5-XS ai-c jc-c-XS mx-5">
              <img
                className="normal grayscale hover:grayscale-0"
                src={binance}
                alt="binance"
              ></img>
            </li>
            <li className="d-f fd-r mb-5-XS ai-c jc-c-XS mx-5">
              <img
                className="h-12 w-12 grayscale hover:grayscale-0"
                src={polygon}
                alt="polygon"
              ></img>
            </li>

            <li className="d-f fd-r mb-5-XS ai-c jc-c-XS mx-5">
              <img
                className="normal grayscale hover:grayscale-0"
                src={solana}
                alt="solana"
              ></img>
            </li>
            <li className="d-f fd-r  mb-5-XS ai-c jc-c-XS">
              <img
                className="h-20 w-20 grayscale hover:grayscale-0"
                src={tron}
                alt="tron"
              ></img>
            </li>
          </ul>
        </div>
      </Ecosystem> */}
    </Wrapper>
  );
};

const Content = styled.div``;

const Ecosystem = styled.div`
  .wrapper-team {
  }
  .normal {
    width: 90px;
    height: 90px;
  }
  .small {
    width: 35px;
    height: 35px;
  }

  .divider {
    list-style-type: none;
    height: 50px;
    border-right: 1px solid #ccc;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  min-height: 200px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;

  h1 {
    font-weight: 700;
    font-size: 96px !important;
    line-height: 100px !important;
    font-feature-settings: unset;
  }

  p {
    line-height: 150%;
    font-feature-settings: unset;
    font-weight: 400;
  }
`;

export default Intro;
