import { useEffect } from "react";
import styled from "styled-components";

const DateTimeDisplay = ({ value, type, className }) => {
  useEffect(() => {
  }, [value]);

  return (
    <Main className={className}>
      <h1 className="fs-30-S">{value}</h1> <h3>{type}</h3>
    </Main>
  );
};

const Main = styled.div``;

export default DateTimeDisplay;
