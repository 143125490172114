import styled from "styled-components";
import discord from "../../assets/Icons/discord.svg";
import twitter from "../../assets/Icons/twitter.svg";
import telegram from "../../assets/Icons/telegram.svg";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("common");
  const date = new Date();
  const enUSFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <FooterStyle className="w-full fd-c jc-c fw-400 bg-zinc-900 md:bg-neutral-900 px-5  md:mt-0 md:pt-20">
      <div className="w-full fd-c f-white-200 my-5 items-center">
        <div className="text-xs md:text-md">
          © 2023 Gyrowin [ {t("Last updated")}: {enUSFormatter.format(date)} ]
        </div>
        <ul className="w-full pt-3 flex items-center justify-center">
          <li>
            <a
              href="https://discord.gg/gyrowin"
              target="_blank"
              rel="noreferrer"
            >
              <img src={discord} alt="discord" width={20} height={20}></img>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/Gyrowin"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="twitter" width={20} height={20}></img>
            </a>
          </li>
          <li>
            <a href="https://t.me/gyrowin" target="_blank" rel="noreferrer">
              <img src={telegram} alt="telegram" width={20} height={20}></img>
            </a>
          </li>
        </ul>
      </div>
    </FooterStyle>
  );
};

const FooterStyle = styled.div`
  ul {
    display: flex;
    flex-direction: row-reverse;
  }

  li {
    list-style-type: none;
    margin-left: 20px;
  }
`;

export default Footer;
