import styled from "styled-components";
import { useTranslation } from "react-i18next";

import chainlink from "../assets/Icons/mainChainlink.svg";
import pyth from "../assets/Icons/pyth.svg";

const Technical = () => {
  const { t } = useTranslation();
  return (
    <Main
      id="Technical"
      className="d-f fd-c jc-c ai-c f-white-200 py-10-S px-10-S px-10-XS py-15-XS"
    >
     <div className="fw-500">{t("technical.title")}</div>
      <div className="w-50 wrapper-team my-8-S my-15-XS p-8-S py-10-XS px-20-XS rad-fix-10-S rad-fix-30-XS">
        <ul className="w-100 mt-10-XS d-f fd-r-S fd-c-XS jc-sa">
          <li className="d-f fd-r mb-5-XS ai-c">
            <img className="" src={chainlink} alt="chainlink"></img>
          </li>
          <li className="d-f fd-r  mb-5-XS ai-c">
            <img className="" src={pyth} alt="pyth"></img>
          </li>
        </ul>
      </div>
      {/* <li className="d-f fd-r mb-5-XS ai-c">
            <div className="flex flex-row">
            <img className="" src={chainlink} alt="chainlink"></img>
            <div className="ml-2 text-blue-300">
              <div className="font-bold">Price Feed</div>
              <div className="text-xs font-semibold">SECURED WITH CHAINLINK </div>
            </div>
            </div>
            
          </li>
          <li className="d-f fd-r mb-5-XS ai-c">
            <div className="flex flex-row">
            <img className="" src={chainlink} alt="chainlink"></img>
            <div className="ml-2">
              <div className="font-bold">VRF</div>
              <div className="text-xs font-semibold">SECURED WITH CHAINLINK </div>
            </div>
            </div>
            
          </li>
          <li className="d-f fd-r mb-5-XS ai-c">
            <div className="flex flex-row">
            <img className="" src={chainlink} alt="chainlink"></img>
            <div className="ml-2">
              <div className="font-bold">Automation</div>
              <div className="text-xs font-semibold">SECURED WITH CHAINLINK </div>
            </div>
            </div>
          </li> */}
    </Main>
  );
};


const Main = styled.div`
  background: #00063d;
  .wrapper-team {
    background: rgba(0, 0, 0, 0.2);
  }
  img {
    width: 90px;
    height: 90px;
  }
`;

export default Technical;
