import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Text,
  ReferenceDot,
  ReferenceDotProps,
} from "recharts";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { NoSymbolIcon } from "@heroicons/react/20/solid";

// import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ContentBox from "./ContextBox";
import FlipNumbers from "react-flip-numbers";
import { useCallback, useEffect, useState } from "react";

import * as numbers from "../utils/numbers";
import ChartRangeButtons from "./ChartRangeButtons";

import { useInterval } from "../hooks/useInterval.tsx";
import ChartApi from "../utils/fetchBuyback";

dayjs.extend(relativeTime);

export const formatDateAxis = (date, days) => {
  if (days === 1) {
    return dayjs(date).format("h:mma");
  } else {
    return dayjs(date).format("D MMM");
  }
};

export const DAILY_SECONDS = 86400;
export const DAILY_MILLISECONDS = 86400000;

const BurnChart = ({
  title,
  xKey,
  yKey,
  domain,
  tickFormat,
  token,
  startColor,
  endColor,
  lineColor,
}) => {
  const { t } = useTranslation("common");

  const [mouseData, setMouseData] = useState(null);
  //   const [flipPrices, setFlipPrices] = useState(false);
  const [daysToShow, setDaysToShow] = useState("30");

  const [burn, setburn] = useState([]);

  const filteredData = useMemo(() => {
    if (!burn.length) return [];
    const start = Number(daysToShow) * DAILY_MILLISECONDS;
    
    const filtered = burn.filter((d) => {
      const dataTime = new Date(d[xKey]).getTime();

      const now = new Date().getTime();
      const limit = now - start;
      return dataTime >= limit;
    });
    return filtered;
  }, [burn, daysToShow, xKey]);

  const handleMouseMove = (coords) => {
    if (coords.activePayload) {
      setMouseData(coords.activePayload[0].payload);
    }
  };

  const handleMouseLeave = () => {
    setMouseData(undefined);
  };

  const Fetchburn = useCallback(async () => {
    const newburns = await ChartApi.getBurn();

    
    if (!newburns) return null;

    if (newburns.result.rows.length && burn.length === 0) {
      setburn(newburns.result.rows);
    }
  }, [burn]);

  useEffect(() => {
    Fetchburn();
  }, [Fetchburn]);

  useInterval(async () => {
    Fetchburn();
  }, 864000); // fetch in 1 day interval

  return (
    <ContentBox
      hideBorder
      hidePadding
      className="h-full md:px-20 md:pb-20 text-white"
    >
      {filteredData.length ? (
        <div className="relative">
          <div className="flex flex-col-reverse md:flex-col">
            <div className="md:ml-0 ml-5">
              <div className="flex items-start justify-between">
                <div>
                  <div className="mb-0.5 flex items-center">
                    <p className="text-base text-th-fgd-3">{t(title)}</p>
                  </div>
                </div>
              </div>
              {mouseData ? (
                <>
                  <div className="mb-1 flex flex-row font-display font-bold text-2xl text-th-fgd-1 items-end">
                    {/* {animationSettings["number-scroll"] ? ( */}

                    {/* <span className="mr-1 text-white text-5xl">$</span> */}

                    <FlipNumbers
                      height={35}
                      width={25}
                      play
                      color="white"
                      numbers={numbers.formatNumericValue(mouseData[yKey])}
                    />
                    <span className="ml-2 text-white text-2xl">{token}</span>
                    {/* ) : (
                  <FormatNumericValue value={mouseData.price} />
                )} */}
                    {/* <span
                  className={`ml-0 mt-2 flex items-center text-sm md:ml-3 md:mt-0`}
                >
                  <Change change={calculateChartChange()} suffix="%" />
                </span> */}
                  </div>
                  <p className="text-sm text-th-fgd-4">
                    {dayjs(mouseData.call_block_time).format(
                      "DD MMM YY, h:mma"
                    )}
                  </p>
                </>
              ) : (
                <>
                  <div className="mb-1 flex flex-row font-display font-bold text-2xl text-th-fgd-1  items-end">
                    {/* {animationSettings["number-scroll"] ? ( */}
                    {/* <span className="mr-1 text-white text-5xl">$</span> */}

                    <FlipNumbers
                      height={35}
                      width={25}
                      play
                      color="white"
                      numbers={numbers.formatNumericValue(
                        filteredData[filteredData.length - 1][yKey]
                      )}
                    />
                    <span className="ml-2 text-white text-2xl"> {token}</span>
                    {/* ) : (
                  <FormatNumericValue
                    value={chartData[chartData.length - 1].price}
                  />
                )} */}
                    {/* <span
                  className={`ml-0 mt-2 flex items-center text-sm md:ml-3 md:mt-0`}
                >
                  <Change change={calculateChartChange()} suffix="%" />
                </span> */}
                  </div>
                  <p className="text-sm text-th-fgd-4">
                    {dayjs(filteredData[filteredData.length - 1][xKey]).format(
                      "DD MMM YY, h:mma"
                    )}
                  </p>
                </>
              )}
            </div>
            <div className="relative">
              {setDaysToShow ? (
                <div className="mb-4 sm:absolute sm:-top-1 sm:right-0 sm:mb-0 sm:flex sm:justify-end">
                  <ChartRangeButtons
                    activeValue={daysToShow}
                    names={["24H", "7D", "30D"]}
                    values={["1", "7", "30"]}
                    onChange={(v) => setDaysToShow(v)}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-2 h-40 w-auto md:h-72">
            <div className="h-full md:-mx-2 md:mt-4">
              <ResponsiveContainer>
                <AreaChart
                  data={filteredData}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                >
                  <defs>
                    <linearGradient
                      id="burnGraidentView"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="0%"
                        stopColor={endColor}
                        stopOpacity={0.3}
                      />
                      <stop
                        offset="99%"
                        stopColor={startColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    axisLine={false}
                    dataKey={xKey}
                    tickLine={false}
                    tickFormatter={(d) =>
                      formatDateAxis(d, parseInt(daysToShow))
                    }
                    minTickGap={20}
                    padding={{ left: 20, right: 20 }}
                    tick={{
                      fontSize: 10,
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    dataKey={yKey}
                    type="number"
                    tick={{
                      fontSize: 10,
                    }}
                    tickFormatter={
                      tickFormat ? (v) => tickFormat(v) : undefined
                    }
                    domain={
                      domain
                        ? domain
                        : ([dataMin, dataMax]) => {
                            const difference = dataMax - dataMin;

                            if (difference < 0.01) {
                              return [dataMin - 0.001, dataMax + 0.001];
                            } else if (difference < 0.1) {
                              return [dataMin - 0.01, dataMax + 0.01];
                            } else if (difference < 1) {
                              return [dataMin - 0.1, dataMax + 0.11];
                            } else if (difference < 10) {
                              return [dataMin - 1, dataMax + 1];
                            } else {
                              return [dataMin, dataMax];
                            }
                          }
                    }
                    tickLine={false}
                    padding={{ top: 20, bottom: 20 }}
                  />
                  <RechartsTooltip
                    cursor={{
                      strokeOpacity: 0.09,
                    }}
                    content={<></>}
                  />
                  <Area
                    isAnimationActive={false}
                    type="monotone"
                    dataKey={yKey}
                    stroke={lineColor}
                    strokeWidth={1}
                    fill="url(#burnGraidentView)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative">
          <div className="flex flex-col-reverse md:flex-col">
            <div className="md:ml-0 ml-5">
              <div className="flex items-start justify-between">
                <div>
                  <div className="mb-0.5 flex items-center">
                    <p className="text-base text-th-fgd-3">{t(title)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative md:ml-0 ml-5">
              {setDaysToShow ? (
                <div className="mb-4 sm:absolute sm:-top-1 sm:right-0 sm:mb-0 sm:flex sm:justify-end">
                  <ChartRangeButtons
                    activeValue={daysToShow}
                    names={["24H", "7D","30D"]}
                    values={["1", "7", "30"]}
                    onChange={(v) => setDaysToShow(v)}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-4 flex h-full items-center justify-center p-4 text-th-fgd-3 md:mt-5">
            <div className="">
              <NoSymbolIcon className="mx-auto mb-1 h-6 w-6 text-th-fgd-4" />
              <p className="text-th-fgd-4">{t("No data")}</p>
            </div>
          </div>
        </div>
      )}
    </ContentBox>
  );
};

export default BurnChart;
