import styled from "styled-components";
import { useTranslation } from "react-i18next";
import data from "../../data/team";

const Team = () => {
  const { t } = useTranslation();
  return (
    <Main
      id="Team"
      className="d-f fd-c jc-c ai-c f-white-200 py-10-S px-10-S px-10-XS py-15-XS"
    >
       <div className="mb-5-S text-3xl font-bold">{t("team.title").toLocaleUpperCase()}</div>
      <div className="wrapper-team my-8-S my-15-XS p-8-S py-10-XS px-20-XS rad-fix-10-S rad-fix-30-XS">
        <ul className="mt-10-XS d-f fd-r-S fd-c-XS">
          {data.map((mem) => (
            <li className="d-f fd-r mr-10-S mb-5-XS" key={mem.name}>
              <img src={mem.path} alt={mem.alt} />
              <div>
                <div className="d-f fd-c p-3-S p-7-XS">
                  <h3 className="mb-2-S mb-5-XS">{t(mem.name)}</h3>
                  <p className="fw-600">{t(mem.title)}</p>
                </div>
                <div className="px-3-S px-7-XS">
                  <ul>
                    {t(mem.experience, { returnObjects: true }).map((exp) => (
                      <li key={exp}>{exp}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Main>
  );
};

const Main = styled.div`
  background: #f9c74f;
  .wrapper-team {
    background: rgba(0, 0, 0, 0.2);
  }
  img {
    width: 90px;
    height: 90px;
  }
`;

export default Team;
