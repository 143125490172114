import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Info = () => {
    const {t} = useTranslation();

    return(
        <InfoStyle className="d-f fd-c ai-c my-5-S my-20-XS">
             <div className="mb-5-S text-3xl font-bold">{t('feature.title').toLocaleUpperCase()}</div>
            <p className="py-2-S py-10-XS fs-8-S fs-20-XS px-20-XS"><i>{t('feature.message')}</i></p>
        </InfoStyle>
    );
}

const InfoStyle = styled.div`
    color:white;
`

export default Info;