import styled from "styled-components";

const Loading = () => {
  return (
    <Group>
      <span className="loader mr-2-S mr-10-XS">
      </span>
    </Group>
  );
};

const Group = styled.div`
.loader {
  width: 18px;
  height: 18px;
  border: 3px dotted #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
`;


export default Loading;